<template>
  <div id="detalle-modelo-imagenes">
    <div class="container">
      <h2 class="text-center d-block">Open Banking</h2>
      <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
      <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>
      <div>
        <div class="card-header" id="headingOne" v-if="!status">
          <span><small>Sin información de Open Banking</small></span>
        </div>
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item" v-for="(credential, index) of credentials" :key="credential.id">
            <a class="nav-link" @click.prevent="setActive(index)" :class="{ active: isActive(index) }" href="#">{{credential.credential_bank.name}}</a>
          </li>
        </ul>
        
        <div id="" style="overflow-y: scroll!important;margin-bottom: 100px;">
		      <!-- <div class="container"> -->
            <div class="tab-content mb-4" id="myTabContent" v-for="(credential, index) of credentials" :key="credential.id">
              <div class="tab-pane fade" :class="{ 'active show': isActive(index) }" >

                <!-- Accounts -->
                <div v-if="credential.credentials_accounts.length == 0">
                  Sin Cuentas
                </div>
                <div class=" mb-4 shadow" v-for="account of credential.credentials_accounts" :key="account.id">
                  <div class="row m-0 table-responsive text-center">

                    <table class="table ">
                      <!-- <caption>Lista de Movimientos</caption> -->
                      <thead class="bg-light">
                        <tr>
                          <th class="text-dark" scope="col">Nombré</th>
                          <th class="text-dark" scope="col">Número</th>
                          <th class="text-dark" scope="col">Balance</th>
                          <th class="text-dark" scope="col">Tipo</th>
                          <th class="text-dark" scope="col">Creación</th>
                          <th class="text-dark" scope="col">Detalles</th>
                          <th class="text-dark" scope="col">Movimientos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-primary font-weight-bold"> {{ account.name }} </td>
                          <td class="text-primary font-weight-bold" > {{ account.number }} </td>
                          <td class="text-primary font-weight-bold" > $ {{ account.balance }} </td>
                          <td class="text-primary font-weight-bold" > {{ account.type }} </td>
                          <td class="text-primary font-weight-bold" > {{ (new Date( parseInt(account.dateCreated) )).toLocaleDateString()}} </td>
                          <td class="text-primary font-weight-bold" > 
                            <button type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#detail-account'+account.id">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path fill-rule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                              </svg>
                            </button>

                            <!-- end modal -->
                            <div class="modal fade" :id="'detail-account'+account.id" tabindex="-1" role="dialog" :aria-labelledby="'account'+account.id" aria-hidden="true">
                              <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">Detalles</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="body modal-body">
                                    <div class=" col-sm-12">
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                          Limite Crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_limit }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Número:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.card_number }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Nombre:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.name }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Clabe:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.clabe }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Nombre usuario:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.user_name }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Clabe debito:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.debit_clabe }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Limite crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_credit_limit }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Fecha de cierre del crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ (new Date( parseInt(account.accounts_details.credit_closing_date) )).toLocaleDateString() }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Crédito última fecha de cierre:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ (new Date( parseInt(account.accounts_details.credit_last_closing_date) )).toLocaleDateString() }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Crédito sin pago de intereses:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_non_interest_payment }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Saldo del estado de crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_statement_balance }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Pago mínimo de crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_minimum_payment }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Fecha de vencimiento del crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ (new Date( parseInt(account.accounts_details.credit_due_date))).toLocaleDateString() }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Tasa de porcentaje anual de crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_annual_percentage_rate }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Número de tarjeta de crédito:
                                        </span>
                                        <span class="col text-left">
                                          <p>{{ account.accounts_details.credit_card_number }}</p>
                                        </span>
                                      </div>
                                      <div class="generales-data row">
                                        <span class="heanding-mobile col font-weight-bold">
                                        Saldo de crédito disponible:
                                        </span>
                                        <span class="col text-left">
                                          <p>$ {{ account.accounts_details.credit_available_balance }}</p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end modal -->
                          </td>
                          <td>
                            <button
                              :data-target="'#account-collapse'+ account.id"
                              aria-controls="collapseAccount"
                              aria-expanded="false"
                              class="btn col-md-2"
                              data-toggle="collapse"
                              type="button"
                            >
                              <img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down-blue.svg" width="40px" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div :id="'account-collapse' + account.id" class="collapse table-responsive p-2">
                    <h3 class="text-center"> Movimientos </h3>
                    <table class="table table-striped">
											<caption>Lista de Movimientos</caption>
											<thead class="bg-primary">
												<tr>
													<th class="text-light" scope="col">Creación</th>
													<th class="text-light" scope="col">Descripción</th>
													<th class="text-light" scope="col">Categoria</th>
													<th class="text-light" scope="col">Monto</th>
													<th class="text-light" scope="col">Balance</th>
													<th class="text-light" scope="col">Cargo</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="transaction of account.accounts_transactions" :key="transaction.id">
													<td > {{ (new Date( parseInt(transaction.date) )).toLocaleDateString() }} </td>
													<td >  {{ transaction.cleaned_description }} </td>
													<td > {{ ( transaction.transactions_categories == null) ? 'Sin Categoria' : transaction.transactions_categories.name  }}</td>
													<td > $ {{ transaction.amount }} </td>
													<td > $ {{ transaction.balance }} </td>
													<td > {{ transaction.is_charge ? 'Si' : 'No' }} </td>
												</tr>
											</tbody>
										</table>

                  </div>
                </div>

                <!-- End Accounts -->

              </div>
            </div>
		      <!-- </div> -->
        </div>
      </div>

      

    </div>
    <div v-if="cesion_creditos">
      <VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion/>
    </div>
  </div>
</template>

<script>
  import Votacion from '@/apps/comite/pages/Votacion'
  import api from '@/apps/comite/api/scoring'
  import Modal from '@/components/Modal'
  import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'
export default {
  name: 'open-banking',
  components: {
    Votacion,
    VotacionCesionCredito,
  },

  data: () => ({
    activeItem: 0,
    credentials: [],
    status: false,
    cesion_creditos: false,
  }),

  created: async function() {
    document.querySelectorAll('.loading')[0].style.display = 'block';
    this.solicitud = this.$store.state.solicitud;
    await api.getDataOpenBanking(this.solicitud.solicitud.id)
    .then( response => {
      this.credentials = response.data[0].customer_credentials
      this.status = true;
      console.log(this.credentials);
    })
    document.querySelectorAll('.loading')[0].style.display = 'none';
  },

  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });

    if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },

  computed: {
    
  },

  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    showDetailAccountModal(accountId){
			this.$modal.show('account'+accountId)
    },
    isEmpty(value) {
      if (typeof value == undefined) {
        return "";
      }
      return value;
    }
  },
}

</script>

<style lang="scss" scoped>
  .div-photo {
    padding: 1%;
  }
  .nav-item .nav-link.active,
  .show>.nav-item .nav-link{
      background: #1561d3 !important;
      color: white;
  }


  
</style>